import i18next from "i18next";

const i18n = i18next;

i18n.init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    ar: {
      translations: require("./locales/ar/translations.json"),
    },
    en: {
      translations: require("./locales/en/translations.json"),
    },
    de: {
      translations: require("./locales/de/translations.json"),
    },
    ru: {
      translations: require("./locales/ru/translations.json"),
    },
    tr: {
      translations: require("./locales/tr/translations.json"),
    },
    fr: {
      translations: require("./locales/fr/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["ar", "en", "de", "ru", "tr", "fr"];

export default i18n;
